<template>
    <div class="text-left">
        <!-- <h4 class="my-2">Order ID: #8989</h4> -->
        <div class="">
            <div class="row">

                <Chat :order="order" class="col-12" />
                <!-- <Summary :order="order" class="col-sm-4"/> -->

            </div>
        </div>
    </div>
</template>

<script>
import Chat from "../../components/order/order-message/Chat.vue";
import Summary from "../../components/order/order-message/Summary.vue";
import { mapState } from "vuex";
export default {
    name: "OrderMessages",
    components: {
        Chat,
        Summary
    },

    computed: {
        ...mapState("order", ["order"])
    }

}
</script>


