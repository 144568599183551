<template>
    <div class="">
        <!-- <div class="card mr-2 is-summary mt-2 shadow">
            <div class="card-header">
                Quick Actions
            </div>
            <div class="card-body text-left ">
                
                <div class="btn-group">

                    <router-link :to="{ name: 'Order', params: { id: order.id }}" class="btn btn-primary">Place Order</router-link>
                    <router-link :to="{ name: 'Order', params: { id: order.id }}" class="btn bg-teal">Wallet Topup</router-link>
                    <a href="" class="btn btn-success">Upload Files</a>
                </div>
            </div>
        </div> -->
    </div>
</template>



<script>
export default {
    props: {
        order: {
            type: Object,
            default: () => ({})
        }
    }
}
</script>

<style lang="scss" scoped>

</style>