<template>
  <div>
    <p>Choose the number of additional charts needed to complete your order.</p>

    <div class="form-group row">
      <label class="col-form-label col-12 text-left"
        >Additional Charts</label
      >
      <div class="col-12">
        <div class="input-group">
          <span class="input-group-prepend">
            <button
              class="btn btn-dark btn-icon"
              type="button"
              @click="updateCharts(0)"
            >
              <i class="icon-minus3"></i>
            </button>
          </span>
          <input
            @change="_calculatePrice"
            type="number"
            v-model="orderForm.charts"
            name="charts"
            min="1"
            class="form-control text-center"
            placeholder=""
          />
          <span class="input-group-append">
            <button
              class="btn btn-dark btn-icon"
              type="button"
              @click="updateCharts(1)"
            >
              <i class="icon-plus3"></i>
            </button>
          </span>
        </div>
        <button class="btn btn-primary mt-2" @click="doUpdateOrder">
          Add Charts
        </button>
      </div>
    </div>
  </div>
</template>


<script>
import { mapMutations, mapState, mapActions } from "vuex";
export default {
  name: "AdditionalCharts",
  computed: {
    ...mapState("order", ["orderForm"]),
  },
  methods: {
    ...mapMutations("order", ["updateCharts", "_calculatePrice"]),
    ...mapActions("order", ["updateOrder"]),
    doUpdateOrder() {
      this.updateOrder(this.orderForm)
        .then((res) => {
          // success
          this.$notify({
            title: "Success",
            text: res.data.Message || "Additional charts added successifully",
            style: "success",
          });
          // close modal
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            text: err.response.data ? err.response.data.Message : "Error",
            style: "danger",
          });
        });
    },
  },
};
</script>