var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-left"},[_c('div',{},[_c('div',{staticClass:"p-1"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('h6',{staticClass:"font-weight-bold text-uppercase mb-4"},[_vm._v(" Files: Clients' Uploads ")]),(
              _vm.order.files &&
              _vm.order.files.client_uploads &&
              _vm.order.files.client_uploads.length > 0
            )?_c('div',{staticClass:"border-0 rounded px-0 py-2 mb-2"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table"},[_vm._m(0),_vm._l((_vm.order.files.client_uploads),function(file,index){return _c('tr',{key:index + 'ofiles'},[_c('td',[_c('a',{staticClass:"text-primary",staticStyle:{"cursor":"pointer","word-break":"break-all"},on:{"click":function($event){return _vm.downloadFile(file)}}},[_vm._v(_vm._s(file.file_name || ""))])]),_c('td',[_vm._v(_vm._s(file.date || ""))]),_c('td',[_vm._v(_vm._s(file.type || ""))]),_c('td',[_c('div',{class:file.deletable ? 'btn-group' : ''},[_c('button',{staticClass:"btn btn-outline-primary",on:{"click":function($event){return _vm.downloadFile(file)}}},[(_vm.is_downloading && _vm.activeFile.id == file.id)?_c('span',[_c('img',{attrs:{"width":"20px","src":_vm.loading_gif,"alt":"Loading..."}})]):_c('span',[_vm._v(" Download "),_c('span',{staticClass:"icon-file-download2"})])]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(file.deletable),expression:"file.deletable"}],staticClass:"btn btn-outline-danger",on:{"click":function($event){return _vm.setActive(file)}}},[_c('span',{staticClass:"icon-trash"})])])])])})],2)])]):_c('p',{staticClass:"p-2 alert alert-primary border-0 rounded"},[_vm._v(" No order files. "),_c('br'),_vm._v(" Files that the client has uploaded will appear here. ")])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('h6',{staticClass:"font-weight-bold text-uppercase mb-4"},[_vm._v(" Files: Our Uploads "),_c('button',{staticClass:"btn btn-primary float-right",attrs:{"data-toggle":"modal","data-target":`#${_vm.modalId}`}},[_vm._v(" Upload Files ")])]),(
              _vm.order.files &&
              _vm.order.files.our_uploads &&
              _vm.order.files.our_uploads.length > 0
            )?_c('div',{staticClass:"border-0 rounded px-0 py-2 mb-2"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table"},[_vm._m(1),_vm._l((_vm.order.files.our_uploads),function(file,index){return _c('tr',{key:index + 'ofiles'},[_c('td',[_c('a',{staticClass:"text-primary",staticStyle:{"cursor":"pointer","word-break":"break-all"},on:{"click":function($event){return _vm.downloadFile(file)}}},[_vm._v(_vm._s(file.file_name || ""))])]),_c('td',[_vm._v(_vm._s(file.date || ""))]),_c('td',[_vm._v(_vm._s(file.type || ""))]),_c('td',[_c('div',{class:file.deletable ? 'btn-group' : ''},[_c('button',{staticClass:"btn btn-outline-primary",on:{"click":function($event){return _vm.downloadFile(file)}}},[(_vm.is_downloading && _vm.activeFile.id == file.id)?_c('span',[_c('img',{attrs:{"width":"20px","src":_vm.loading_gif,"alt":"Loading..."}})]):_c('span',[_vm._v(" Download "),_c('span',{staticClass:"icon-file-download2"})])]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(file.deletable),expression:"file.deletable"}],staticClass:"btn btn-outline-danger",on:{"click":function($event){return _vm.setActive(file)}}},[_c('span',{staticClass:"icon-trash"})])])])])})],2)])]):_c('p',{staticClass:"p-2 alert alert-primary border-0 rounded"},[_vm._v(" No order files. "),_c('br'),_vm._v(" Files uploaded by writer, editor or support will appear here. ")])])])])]),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.showConfirm),callback:function ($$v) {_vm.showConfirm=$$v},expression:"showConfirm"}},[_c('v-card',{staticClass:"text-left"},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Confirm ")]),_c('v-card-text',{staticClass:"text-left"},[_vm._v(" Are you sure you want to delete "),_c('strong',[_vm._v(_vm._s(_vm.activeFile.file_name || ""))]),_vm._v("? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.showConfirm = false;
            _vm.activeFile = {};}}},[_vm._v(" No, Cancel ")]),_c('v-btn',{attrs:{"color":"error darken-1","text":""},on:{"click":function($event){return _vm.deleteFile(_vm.activeFile)}}},[_vm._v(" Yes, Delete ")])],1)],1)],1),_c('BaseModal',{attrs:{"title":_vm.title,"modalId":_vm.modalId}},[_c(_vm.component,{tag:"component"})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Filename")]),_c('th',[_vm._v("Uploaded At")]),_c('th',[_vm._v("Uploaded By")]),_c('th',[_vm._v("Action")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Filename")]),_c('th',[_vm._v("Uploaded At")]),_c('th',[_vm._v("Uploaded By")]),_c('th',[_vm._v("Action")])])])
}]

export { render, staticRenderFns }