<template>
  <div class="row">
    <div class="col-12">
      <p>Choose files you need to upload.</p>

      <div class="form-group">
        <label for="inputEmail3" class="col-form-label text-left"
          >You can select multiple files</label
        >
        <div class="">
          <input
            type="file"
            @change="setUploadFiles"
            class="form-control form-control-lg"
            id="inputEmail3"
            placeholder="Choose files to upload"
            multiple="multiple"
          />
          <div
            class="mt-3 p-3 bg-teal-300 text-white rounded"
            v-if="orderForm.files.length > 0"
          >
            Selected files: <br />
            <strong v-for="(file, index) in orderForm.files" :key="index"
              >{{ file.name }} <br
            /></strong>
          </div>
        </div>
      </div>

      <div class="form-group mb-4" style="max-width: 100%;overflow-x: scroll;">
        <label for="inputEmail3" class="col-form-label text-left"
          >File Type</label
        >
        <v-btn-toggle
          v-model="orderForm.file_type"
          active-class="primary white--text"
          mandatory
          rounded
          small
        >
          <v-btn value="order file">Order File</v-btn>
          <v-btn value="plagiarism report">Plagiarism Report</v-btn>
          <v-btn value="grammarly report">Grammarly Report</v-btn>
          <v-btn value="draft">Draft</v-btn>
        </v-btn-toggle>
      </div>
      <button class="btn btn-primary btn-lg mt-2" @click="uploadFiles">
        Upload
      </button>
    </div>
  </div>
</template>


<script>
import { mapMutations, mapState, mapActions } from "vuex";
export default {
  name: "AdditionalPages",
  computed: {
    ...mapState("order", ["orderForm", "order"]),
  },
  methods: {
    ...mapMutations("order", ["updatePages", "_getOrder", "_setUploadFiles"]),
    ...mapActions("order", ["updateOrder", "_uploadFiles"]),
    doUpdateOrder() {
      this.updateOrder(this.orderForm)
        .then((res) => {
          // success
          this.$notify({
            title: "Success",
            text: res.data.Message || "Order updated",
            style: "success",
          });
          // close modal
          console.log(res);
        })
        .catch((err) => {
          console.log("file upload err", err);
          this.$notify({
            title: "Error",
            text: err.response.data ? err.response.data.Message : "Error",
            style: "danger",
          });
        });
    },
    setUploadFiles(event) {
      this._setUploadFiles(event.target.files);
    },
    uploadFiles() {
      console.log("this.orderForm", this.orderForm);
      this._uploadFiles(this.orderForm)
        .then((res) => {
          // success
          this.$notify({
            title: "Success",
            text: "Files Uploaded",
            style: "success",
          });
          // close modal
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            text: err.response.data ? err.response.data.Message : "Error",
            style: "danger",
          });
        });
    },
  },
};
</script>